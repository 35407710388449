.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.loadingContainer {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loadingContainer img {
  width: 150px;
  margin: 0 auto;
  display: block;
}

.alignRight {
  text-align:right;
}

.searchBox {
  margin-bottom: 20px;
}

.textSearch {
  width: 300px !important;
  margin-right: 10px;
}

.userSearch {
  margin-right: 10px;
}

.jobSearch {
  margin-right: 10px;
}

.datePicker {
  padding-right:5px;
  color: #495057;
}

.dateButton {
  border: 1px solid #ced4da;
  color: #495057;
}

.dateButton:hover {
  background-color: #ced4da;
}

.exportButton {
  float: right;
  margin-bottom: 10px;
}

.checkboxes {
  margin-top: 20px;
}

.addButton {
  margin-right: 0;
  margin-left:auto;
}

.activeState {
  background-color: #eaf4ff;
}

.completeState {
  background-color: #edfff2;
}

.activeState:hover {
  background-color: #bed8f4;
}

.completeState:hover {
  background-color: #b8e7c5;
}

.noHover{
  pointer-events: none;
}