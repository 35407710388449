.home {
  padding: 80px 0;
  text-align: center;
}

.home h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.jobList {
  text-align: left;
}

.home .jobItem h6 {
  margin-bottom: 0px !important;
}

.home .jobItem .address {
  font-size: 12px;
}

.home .jobItem .summary {
  padding-top: 10px;
  margin-bottom: 0px;
}

.noJobs {
  padding-top: 50px;
}