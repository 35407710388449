.job {
    margin-top: 70px;
}

.jobPanel {
    margin-top: 25px;
    /* background-color: #f6f6f6; */
    /* border-color: #d3d3d3e7; */
}

.jobState {
    float: right;
    font-size: 15px;
    font-weight: 400
}

.footnote {
    font-size: 14px;
}

.hr-sect {
	display: flex;
	flex-basis: 100%;
	align-items: center;
	color: rgba(0, 0, 0, 0.35);
    margin: 8px 0px;
    margin-top: 60px;
    margin-bottom: 20px;
}
.hr-sect::before,
.hr-sect::after {
	content: "";
	flex-grow: 1;
	background: rgba(0, 0, 0, 0.35);
	height: 1px;
	font-size: 0px;
	line-height: 0px;
	margin: 0px 8px;
}

.newEntry {
    margin-bottom:10px;
}

.complete {
    float: right;
}

.updateButton {
    float: right;
}